import React from 'react'
import Head from '../components/head'
import Info from '../components/info'
import Work from '../components/work'
import '../styles/index.scss'
import FadeIn from 'react-fade-in';

const indexPage = () => {
  return (
    <FadeIn>
      <Head />
      <Info />
      <Work />
    </FadeIn>
  )
}

export default indexPage