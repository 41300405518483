import React from 'react'

const Info = () => {
    return (
        <div id="info">
            <div class="info-title">
                <a href="mailto:mail@jcr.nyc" target="_blank" rel="noreferrer">
                <svg version="1.1" viewBox="0 0 521.034 189.6932" preserveAspectRatio="xMinYMin meet">
                    <g>
                        <path d="M314.9,28.2c2.2-4.1,3.5-8,3.5-11.5c0-3.6-1.3-6.5-3.8-8.6c-2.5-2-5.9-3-10.1-3c-8.8,0-19.7,3-31.5,8.2
                            c-11.6,5.1-24,12.5-35.8,21.5c-9.6-3.3-20.8-4.9-32.4-4.9c-14.7,0-26.9,2.9-35.5,8c-8.6,5.2-13.6,12.8-13.6,22
                            c0,13.2,11.3,23.5,28.2,26.6l0,0l0.2-0.8l0.1-0.4l-0.2,1.2c4.1,0.7,6,1,11.1,1.2c-3.6,8.8-5.3,17.2-5.3,26.1
                            c0,10.7,2.9,19.5,8.2,25.7c5.3,6.1,12.9,9.5,22,9.6c1.4,5.5,4,9.9,7.6,13c3.8,3.3,8.7,5,14.2,5c6,0,11.1-1.7,16.6-5.9
                            c0.6-0.4,0.7-1.2,0.2-1.8l-1,0.8l0,0l1-0.8l-1.2-1.6l-1,0.8l-0.8-1c-4.2,3.5-9,5.2-13.8,5.2c-6.2,0-12.1-3.4-15.1-8.6
                            c-1-1.8-1.4-2.8-2-5.3c7.7-0.8,14.3-3.6,21.8-9l0,0c7.6-5.5,14.6-13,19.6-20.8c5-7.7,8.3-15.8,8.3-22.2c0-3-1.1-5.5-3-7.4
                            c-1.9-1.8-4.7-2.8-7.8-2.8c-6.1,0-12.8,3-19,7.8c-6.3,4.9-12.2,11.8-16.7,20l1.1,0.6c0,0,0,0,0,0l-0.1-0.1l-1-0.6l0,0
                            c-5.1,9.2-8.5,20-8.5,28v2.1c-3.2-0.2-5.6-0.7-7.6-1.5c-2.2-0.9-3.9-2.1-5.5-3.9c-3.2-3.5-4.5-7.4-4.5-14.5c0-9.1,3-20.4,7.8-30.6
                            c1-1.8,2.3-4.4,4.3-7.7c19.3-2.1,41.2-9.4,60-20.5l0,0c12.6-7.2,23.7-16,31.6-24.7C309.5,36.6,312.7,32.3,314.9,28.2z M272.5,62.6
                            L272.5,62.6l-0.6-1L272.5,62.6L272.5,62.6z M247.8,40.2L247.8,40.2L247.8,40.2C247.8,40.2,247.8,40.2,247.8,40.2
                            C247.8,40.2,247.8,40.2,247.8,40.2z M313.5,16.9c0,2.7-1,6-3.1,9.7c-2,3.7-5,7.6-8.8,11.7c-7.5,8.1-18,16.4-29.8,23.2l0,0l0,0
                            c-17.2,10.2-36.9,17-55.2,19.5c4.5-7.4,7-10.9,12.7-18.4c6.7-8.4,9.5-11.9,17.1-19.5c6.4,3.2,13.4,7.6,18.1,14.7
                            c0.2,0.3,0.6,0.5,0.9,0.6c0.4,0,0.8-0.1,1-0.4l-0.9-0.9l0,0l0.9,0.9l1.4-1.4l-0.9-0.9l0,0l0.9,0.9c0.4-0.4,0.5-1.1,0.2-1.6
                            c-5-7.3-11.9-11.9-18-15.1c11.3-9.8,21.6-17.2,30.7-22.2c9.5-5.2,17.6-7.8,24.3-7.8c3,0,5.1,0.7,6.4,1.8
                            C312.8,12.8,313.5,14.4,313.5,16.9z M228.3,61.8L228.3,61.8L228.3,61.8L228.3,61.8z M197,82.8h-0.8c-10.8,0-19.8-2.4-26-6.5
                            c-6.2-4.1-9.7-9.8-9.7-16.5c0-7.5,4.1-13.8,11.7-18.3c7.7-4.5,18.8-7.1,32.5-7.1c9.6,0,20.5,1.5,28.1,3.7
                            c-9.6,7.9-13.5,12.2-20.8,21.6l0,0C205,68.4,202.2,72.6,197,82.8z M213,60.4L213,60.4L213,60.4L213,60.4z M214.2,82.7L214.2,82.7
                            C214.2,82.7,214.2,82.7,214.2,82.7C214.2,82.7,214.2,82.7,214.2,82.7z M237.5,36.1L237.5,36.1L237.5,36.1L237.5,36.1z M244.7,137.2
                            l-0.8-1c-7.1,5.2-13,7.6-19.8,8.2v-1.7c0-13.1,8.1-30.4,19.8-41.6c0,0,0,0,0,0l-0.9-0.9l0,0l0.9,0.9c6.8-6.6,14.2-10.2,19.8-10.2
                            c2,0,3.5,0.6,4.5,1.6c1,1,1.7,2.4,1.7,4.2c0,5.4-2.9,12.6-7.7,19.9c-4.8,7.2-11.3,14.4-18.3,19.5l0,0L244.7,137.2L244.7,137.2z
                            M221.1,147.7C221.1,147.7,221.1,147.7,221.1,147.7L221.1,147.7L221.1,147.7z M208.5,93.2L208.5,93.2L208.5,93.2L208.5,93.2z
                            M273.3,64.5L273.3,64.5L273.3,64.5L273.3,64.5z"/>
                        <path d="M389.6,91c0-0.5-0.3-1-0.7-1.2l-0.6,1.2L389.6,91z"/>
                        <path d="M510.3,124.7c-6.3,0-11,5.6-11,11.8c0,1.8,0.5,3.4,1.7,4.6c1.2,1.2,2.9,1.8,4.8,1.8c6.1,0,11-5.1,11-12
                            c0-1.8-0.6-3.4-1.8-4.5C513.8,125.3,512.1,124.7,510.3,124.7z"/>
                        <path d="M520.7,0.4C520.4,0.1,520,0,519.6,0c-17.1,2.4-32.2,7.2-46,15C463,8.4,447.6,4.5,429,4.5c-27.6,0-53.6,9.4-72.5,25.2l0,0
                            c-14.9,12.5-24,27.4-24,41.7c0,10.4,4.6,18.5,13.2,22.1c0.4,6.2,2.3,11.3,5.5,15c3.4,3.8,8.2,5.9,14,5.9
                            c12.4,0,23.9-10.9,24.4-23.3l-1.3,0l0,0l0,0l0.6-1.2l-1.6-0.8c-0.4-0.2-0.8-0.2-1.2,0s-0.6,0.6-0.7,1l1.3,0.1l0,0l-1.3-0.1
                            c-0.8,10-9,19.7-19.8,19.7c-4,0-7.2-1.5-9.4-4c-2.1-2.5-3.4-6.1-3.5-10.5c1.7,0.2,2.8,0.1,4.2,0.1c13.2,0,27.7-7.1,40.2-18.8
                            c0,0,0,0,0,0l-0.9-0.9c0,0,0,0,0,0l0.9,0.9c11.4-11,18.2-22.9,18.2-32.9c0-3.9-1.3-7.2-3.7-9.5c-2.4-2.3-5.9-3.5-10-3.5
                            c-7.1,0-15.1,2.9-22.7,7.6c-7.7,4.8-15.1,11.6-21,19.6l0,0l0,0l1,0.8l-0.8-0.6l0.8,0.6c0,0,0,0,0,0l0,0l-1-0.8
                            c-7,9.5-11.2,20.5-12.1,30.6c-5.1-2.9-8.5-9.1-8.5-17.2c0-12.6,8.2-26.5,22.2-38.1l-0.8-1l0,0l0.8,1l0,0c18-15.2,43-23.6,67-23.6
                            c17.7,0,31.3,3.6,39.9,10c-22.9,16-34.2,32.6-54.4,63.9l0,0c-8.2,12.6-19.1,28.4-32.3,41.1c-13.2,12.6-28.5,21.9-45.6,21.9
                            c-5.5,0-10.9-1.5-15-3.8c-4.1-2.3-6.4-5.3-6.4-7.9c0-1,0.3-1.8,1-2.7c0.6-0.9,1.5-1.8,2.5-2.8c1.9-1.9,4.2-4.3,4.2-7.8
                            c0-2-0.6-3.7-1.8-4.9c-1.2-1.2-2.9-1.8-4.8-1.8c-2.8,0-5.1,1.4-6.7,3.6c-1.6,2.2-2.4,5.2-2.4,8.7c0,7.7,3.5,14.1,9.4,18.5
                            c5.8,4.4,14,6.7,23.3,6.7c42,0,72.1-28.2,88.1-64.6v0c13.2-30.2,23.3-50.3,44.4-64.9c4.9,5.1,7.6,11.4,7.6,19.2
                            c0,16.7-12.2,31.9-31,34.3c-3.4-1-5.8-1.4-8-1.4c-0.9,0-1.7,0.2-2.4,0.6c-0.7,0.4-1.3,1.1-1.3,2.1c0,0.6,0.2,1.2,0.6,1.7
                            c0.4,0.5,0.9,0.8,1.4,1c1.1,0.4,2.5,0.6,4.1,0.6c0,0,0,0,0,0l5-0.2c1.2,0.5,2.8,1.5,4,2.9c1.3,1.5,2.2,3.1,2.2,4.5
                            c0,2.3-0.9,4.8-2.5,7.6c-1.6,2.7-3.7,5.6-5.8,8.6c-4.3,6-8.8,12.8-8.8,20.4c0,5.8,1.6,11.4,4.1,15.7c2.5,4.2,6.1,7.3,10.3,7.3
                            c3.7,0,8-1.4,12.7-3.9c4.7-2.4,9.8-5.9,14.9-10.1c10.3-8.5,21.2-20,29.9-32.3c0.2-0.3,0.3-0.8,0.2-1.1c-0.1-0.4-0.4-0.7-0.8-0.8
                            l-1.8-0.6c-0.6-0.2-1.2,0-1.5,0.5l1.1,0.7l0,0l-1.1-0.7c-4.4,6.8-14,17.7-24.4,26.9c-5.2,4.6-10.6,8.7-15.6,11.7
                            c-5,3-9.5,4.7-13,4.7c-1.1,0-2-0.4-2.7-1.2c-0.7-0.8-1.2-2.1-1.2-4.2c0-3.2,0.9-6.6,2.3-10c1.4-3.5,3.3-7,5.2-10.6
                            c1.9-3.6,3.9-7.2,5.3-10.7c1.5-3.6,2.5-7.2,2.5-10.8c0-6.2-3.5-11.8-8.7-15.5c11-2.1,20.1-6.1,26.5-11.9
                            c7.2-6.3,11.1-14.7,11.1-24.7c0-10-4.5-18.6-12.5-25.1c12.6-6.9,27.4-11.6,41.6-13.5l-0.2-1.3l0,0l0,0l1.3,0.3l0.4-1.8
                            C521.1,1.2,521,0.7,520.7,0.4z M347,92.6L347,92.6L347,92.6C347,92.6,347,92.6,347,92.6z M375.8,47c8.6-7.8,17.8-12,25.7-12
                            c3.2,0,5.6,0.9,7.1,2.3c1.5,1.4,2.4,3.5,2.4,6.4c0,8.4-6,19.2-16.6,29.6c-11.6,11-25.5,17.7-36.6,17.7c-1.8,0-2.8,0.1-4.9-0.2
                            C354.6,75,363,58.7,375.8,47z M395.2,74.2L395.2,74.2L395.2,74.2L395.2,74.2z M473.6,16.5L473.6,16.5L473.6,16.5
                            C473.6,16.5,473.6,16.5,473.6,16.5z M413.2,84.1L413.2,84.1L413.2,84.1L413.2,84.1z"/>
                        <path d="M257.5,157.7c-0.2-0.3-0.5-0.5-0.9-0.5c-0.3,0-0.7,0.1-1,0.3l0.8,1L257.5,157.7z"/>
                        <path d="M285.9,124.7c-6.3,0-11,5.6-11,11.8c0,1.8,0.5,3.4,1.7,4.6c1.2,1.2,2.9,1.8,4.8,1.8c6.1,0,11-5.1,11-12
                            c0-1.8-0.6-3.4-1.8-4.5C289.4,125.3,287.8,124.7,285.9,124.7z"/>
                        <path d="M519.5,4.4c0.5-0.1,1-0.5,1.1-1l-1.3-0.3L519.5,4.4z"/>
                        <path d="M157.1,124.7c-6.3,0-11,5.6-11,11.8c0,1.8,0.5,3.4,1.7,4.6c1.2,1.2,2.9,1.8,4.8,1.8c6.1,0,11-5.1,11-12
                            c0-1.8-0.6-3.4-1.8-4.5C160.6,125.3,159,124.7,157.1,124.7z"/>
                        <path d="M136.9,91c-3.6-2-8.5-3.1-14.4-3.3c6.8-12.6,9.7-17.7,14.6-25.8l0,0c6.6-11.1,12.7-19.3,19.7-28
                            c7.4-8.8,12.9-14.2,19.3-18.9l1.6-0.5c4.3-1.3,7.5-2.6,9.6-3.8c1.1-0.6,2-1.3,2.6-2c0.6-0.7,1.1-1.6,1.1-2.5c0-0.8-0.3-1.5-0.9-1.9
                            c-0.5-0.4-1.1-0.6-1.7-0.6l0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0v0c-0.8,0-1.8,0.2-2.7,0.5c-1,0.4-2.2,0.9-3.4,1.6
                            c-2.4,1.3-5.3,3.2-8.1,5.5c-3.1,1-7.4,1.7-12.2,2.1c-4.8,0.4-10,0.6-14.4,0.8c0,0,0,0,0,0l0.1,1.3l0,0l-0.1-1.3
                            c-12.6,0.8-16.2,1.2-23.6,2.5c-1.4-3.6-3.3-6.4-5.8-8.4c-2.7-2.2-6.1-3.2-9.9-3.2c-7.6,0-14.7,5.4-14.7,12.6c0,0.4,0.2,0.8,0.5,1
                            l1.6,1.2c0.4,0.3,0.9,0.3,1.4,0.1c0.4-0.2,0.7-0.7,0.7-1.2c0-3.2,1.5-5.5,3.5-7.1c2.1-1.6,4.7-2.4,6.7-2.4c3,0,5.5,0.9,7.4,2.4
                            c1.7,1.3,3,3.2,3.9,5.5c-11.7,2.8-22.4,6.7-31.9,14.5l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0C76.4,40.8,69.7,53,69.7,64.3
                            c0,5.4,1.8,10.1,4.9,13.4c3.2,3.3,7.7,5.1,13,5.1c9.1,0,18.1-5.8,25.5-15.7l0,0c5.4-6.8,9.9-16.7,11.8-25l-1.3-0.3l0,0l0,0l1.2,0.3
                            v0c0.8-3.7,1.2-7.7,1.2-11.8c0-4.1-0.2-5.9-1-9.4c5.5-1.2,14.7-1.7,22.6-2.1l0,0c7.1-0.3,13.6-0.7,18.7-1.5
                            c-14.7,13.4-32.3,36.5-56.2,71.1c-7.2,1-14.2,2.6-21.9,5.2v0c-9,2.9-15.7,5.7-26.7,11.4c0,0,0,0,0,0l0.6,1.1l0,0h0l-0.6-1.1
                            l-1.1,0.6c-4-3.2-6.3-7.3-7-11.2l0,0c-0.1-0.6-0.4-1.2-1.1-1.5c-0.6-0.3-1.2-0.2-1.6,0c-0.8,0.3-1.6,1.3-1.4,2.5l0,0c0,0,0,0,0,0
                            c0,0,0,0,0,0l0,0c0.8,4.5,3,8.9,7,12.5c-16.5,10.2-30.4,21.6-40.3,32.7C6,152.2,0,163.6,0,173.2c0,4.8,1.9,9,5.2,11.9
                            c3.3,2.9,7.9,4.6,13.3,4.6c8.4,0,17.7-2.7,27.3-7.6l0,0c10.9-5.3,23.6-15,32.8-24.6c9.1-9.5,19.3-23.4,27.2-37.3
                            c5.2-0.1,14.1-1.4,21.9-4.2c4.1-1.5,8-3.4,10.9-5.9c2.9-2.5,4.9-5.6,4.9-9.4C143.3,96.5,140.9,93.2,136.9,91z M105,118.9L105,118.9
                            C105,118.9,105,118.9,105,118.9L105,118.9z M113.1,103.6l1.1,0.6c0,0,0,0,0,0l5.5-10.9c0,0,0,0,0-0.1l0.5-1.1h1
                            c5.8,0,10.2,0.9,13.2,2.5c2.9,1.6,4.3,3.7,4.3,6.1c0,3.3-2.8,6.7-8.7,9.5c-5.3,2.6-12.8,4.6-21.8,5.4l6-11.5L113.1,103.6
                            L113.1,103.6L113.1,103.6z M90.6,115.6c-10.3-1-19.6-3.5-25.9-7.1c10-5.3,16.5-8.1,25.3-10.8c0,0,0,0,0,0l-0.4-1.2l0,0l0.4,1.2
                            c4-1.4,7.8-2.4,11.4-3.2c2.3-0.5,3.7-0.8,5.2-1L90.6,115.6z M60.2,109.4C60.2,109.4,60.2,109.4,60.2,109.4L60.2,109.4L60.2,109.4
                            C60.2,109.4,60.2,109.4,60.2,109.4z M4.6,173c0-8.2,5.2-18.4,15-29.3c9.6-10.8,23.5-22.1,40.6-32.7c9.8,6.3,19.6,7.8,27.2,9
                            L72.5,140l1,0.8l0,0l-1-0.8l0,0c-8.4,11.5-17.5,22.8-26.9,31.1c-9.4,8.4-18.9,13.7-27.9,13.7c-4.1,0-7.4-1.2-9.6-3.2
                            C5.9,179.7,4.6,176.7,4.6,173z M91.2,116.9L91.2,116.9L91.2,116.9L91.2,116.9z M109.4,91.8L109.4,91.8
                            C109.4,91.8,109.4,91.8,109.4,91.8L109.4,91.8z M155.8,33.1L155.8,33.1L155.8,33.1L155.8,33.1z M171,15.1L171,15.1
                            C171,15.1,171,15.1,171,15.1C171,15.1,171,15.1,171,15.1z M177.3,13.2L177.3,13.2L177.3,13.2L177.3,13.2z M174.6,12.4L174.6,12.4
                            L174.6,12.4C174.6,12.4,174.6,12.4,174.6,12.4z M82.1,72.7c-2-2.3-3.2-5.8-3.2-10.2c0-12,9.3-25.8,23-33.5c0,0,0,0,0,0l-0.6-1.1
                            l0,0h0l0.7,1.1c6-3.6,11.5-5.7,18.8-7.3c0.5,2.3,0.9,5.5,0.9,8.8c0,10.8-4.8,24.3-12.1,33.8v0l0.7,0.6l0.3,0.2l-1-0.8
                            c-6,7.7-13.3,12.1-19,12.1C87,76.3,84.1,75,82.1,72.7z M111,89.6L111,89.6L111,89.6C111,89.6,111,89.6,111,89.6z M60.4,107.2
                            L60.4,107.2L60.4,107.2L60.4,107.2z M136,61.2L136,61.2L136,61.2L136,61.2z M52.3,94.6L52.3,94.6L52.3,94.6L52.3,94.6z"/>
                </g>
            </svg>
            </a>
            </div>
            <h2 class="info-about">John Christian Rose is a designer and art director based in New York, and currently working at <a href="https://somethingspecialstudios.com/" target="_blank" rel="noreferrer">Something Special Studios</a>. Previously, he’s worked at <a href="https://www.takeagander.com/" target="_blank" rel="noreferrer">Gander</a>, <a href="https://badbadbadbad.com/" target="_blank" rel="noreferrer">B.A.D. Studio</a>, and <a href="https://www.pacificpacific.pub/" target="_blank" rel="noreferrer">Pacific Books</a>. He’s made projects for musicians such as <a href="https://en.wikipedia.org/wiki/Flying_Lotus" target="_blank" rel="noreferrer">Flying Lotus</a>, <a href="https://en.wikipedia.org/wiki/Solange_Knowles" target="_blank" rel="noreferrer">Solange</a>, and <a href="https://en.wikipedia.org/wiki/Ty_Segall" target="_blank" rel="noreferrer">Ty Segall</a>, publications like <a href="https://www.vice.com/" target="_blank" rel="noreferrer">Vice</a>, <a href="https://garage.vice.com/" target="_blank" rel="noreferrer">Garage</a>, and <a href="http://packet-biweekly.com/" target="_blank" rel="noreferrer">Packet Biweekly</a>, and venues such as <a href="http://www.goodroombk.com/" target="_blank" rel="noreferrer">Good Room</a>, <a href="https://publicrecords.nyc/" target="_blank" rel="noreferrer">Public Records</a>, and <a href="https://www.mohawkaustin.com/" target="_blank" rel="noreferrer">Mohawk Austin</a>. He’s also been written about in <a href="https://eyeondesign.aiga.org/how-to-work-with-flying-lotus-and-ty-segall-while-still-in-design-school/" target="_blank" rel="noreferrer">Eye on Design</a>, <a href="https://intern-mag.com/john-christian-rose/" target="_blank" rel="noreferrer">Intern Mag</a>, and <a href="https://www.itsnicethat.com/articles/john-christian-rose-graphic-design-190619" target="_blank" rel="noreferrer">It’s Nice That</a>. Full portfolio and resume available upon request.</h2>
            <ul class="info-social">
                <li class="info-social-link"><a href="mailto:mail@jcr.nyc" target="_blank" rel="noreferrer">Email</a></li>
                <li class="info-social-link"><a href="https://twitter.com/xtyboi" target="_blank" rel="noreferrer">Twitter</a></li>
                <li class="info-social-link"><a href="https://instagram.com/xtyboi" target="_blank" rel="noreferrer">Instagram</a></li>
                <li class="info-social-link"><a href="https://are.na/john-christian-rose" target="_blank" rel="noreferrer">Are.na</a></li>
            </ul>

            <ul class="info-social-mobile">
                <li class="info-social-link"><a href="mailto:mail@jcr.nyc" target="_blank" rel="noreferrer">EMAIL</a></li>
                <li class="info-social-link"><a href="https://twitter.com/xtyboi" target="_blank" rel="noreferrer">TWIT</a></li>
                <li class="info-social-link"><a href="https://instagram.com/xtyboi" target="_blank" rel="noreferrer">GRAM</a></li>
                <li class="info-social-link"><a href="https://are.na/john-christian-rose" target="_blank" rel="noreferrer">ARE.NA</a></li>
            </ul>
        </div>
    )
}

export default Info