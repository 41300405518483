import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green"}}
        onClick={onClick}
      />
    );
  }

const Work = () => {
    const data = useStaticQuery(graphql`
        query Images {
            titledesc: allMarkdownRemark (
                    sort: { order: ASC, fields: [fields___slug] }
                )
                {
                edges {
                    node {
                        frontmatter {
                            title
                            description
                            year
                            category
                            addendum1
                            addendum2
                            addendum3
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
            images: allFile (
                filter: { ext: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" } },
                sort: { order: ASC, fields: [relativePath] }
            )
            {
                edges {
                    node {
                        fields {
                            imgslug
                        }
                        relativeDirectory
                        id
                        childImageSharp {
                            fluid(webpQuality: 90) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `)
    const setting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: false,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 745,
              settings: {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                draggable: true,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 4000,
                pauseOnHover: false
              }
            }
        ]
    }
    
    console.log(data)
    return (
        <div id="work">
            <ul>
                {data.titledesc.edges.map(titledesc => {
                    return (
                        <li class="work-project-item">
                            <div class="work-project-info">
                                <div class="work-project-info-topinfo">
                                    <h3 class="work-project-info-topinfo-title">{titledesc.node.frontmatter.title}</h3>
                                    <h3 class="work-project-info-topinfo-categoryyear">{titledesc.node.frontmatter.category} ({titledesc.node.frontmatter.year})</h3>
                                </div>
                                <p class="work-project-info-description">{titledesc.node.frontmatter.description}</p>
                            </div>
                            <div class="slider-container">
                                <Slider {...setting}>
                                    {data.images.edges.map(imagez => {
                                        let currentproj = titledesc.node.fields.slug
                                        if(imagez.node.relativeDirectory === currentproj) {
                                            return (
                                                <Img key={imagez.node.childImageSharp.id} fluid={imagez.node.childImageSharp.fluid}/>
                                            )
                                        }
                                    })}
                                </Slider>
                            </div>
                            <p class="work-project-info-addendum">{titledesc.node.frontmatter.addendum1}</p>
                            <p class="work-project-info-addendum">{titledesc.node.frontmatter.addendum2}</p>
                            <p class="work-project-info-addendum">{titledesc.node.frontmatter.addendum3}</p>
                            <p class="work-project-info-addendum">{titledesc.node.frontmatter.addendum4}</p>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Work